import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import classnames from 'classnames';
import { domain } from "../utils";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";


const DownloadsPage = ({ data }) => {
  const { downloadsPage, homepages } = data.strapi;
  const {
    header,
    footer,
  } = homepages[0];
  const {
    language,
    presskit,
    logoBox,
    appScreens,
    zusammenstellung,
    meta
  } = downloadsPage;

  const dispatch = useContext(GlobalDispatchContext)
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language })
  })

  
  return (
    <Layout header={header} footer={footer}>
      <SEO title={meta.title} description={meta.description}/>
      {logoBox.length > 0 && (
        <section className="section position-relative">
          <div className="container-fluid t-cell-middle">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <div className="title-small border-blue mb-2 mb-md-5 ">
                  <h2 className="text-uppercase">Logo</h2>
                </div>
                <div className="container-fluid p-0">
                  <div className="row">
                    {logoBox.map((box, index) => {
                      let key = `${index}_${box.title}` 
                      return (
                        <div
                          key={key}
                          className={classnames('col-12 col-md-6 my-3 my-lg-2', {'extend': index > 1})}
                          data-aos="fade-up"
                          data-aos-once="true"
                        >
                          <div className={classnames('card-download light-shadow')}>
                            <div className="card-download-img my-4 my-md-5 tex-center">
                              <img src={`${domain}${box.img.url}`} alt="blogItem" />
                            </div>
                            <div className="text-center">
                              <h5 className="m-0 d-inline-block my-2">{box.title}</h5>
                              {box.files.length > 0 && (
                                <div className="text-center">
                                  <p key={key} className="d-inline-block">
                                    {box.files.map((file, index) => {
                                      key += `_file_${index}`
                                      return (
                                        <>
                                          <a target="_blank" rel="noopener noreferrer" href={`${domain}${file.url}`} download={`${domain}${file.url}`}>{file.name}</a>
                                          {(index + 1) < box.files.length && (
                                            <span>
                                              {' / '}
                                            </span>
                                          )}
                                        </>
                                      )
                                    })}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {(appScreens.length > 0 || zusammenstellung.length > 0) && (
        <section className="section position-relative mb-5">
          <div className="container-fluid t-cell-middle">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <div className="title-small border-blue mb-2 mb-md-5">
                  <h2 className="text-uppercase">App</h2>
                </div>
                <div className="container-fluid p-0">
                  {appScreens.length > 0 && (
                    <div className="row">
                      {appScreens.map((screenBox, index) => {
                        let key = `${index}_${screenBox.title}` 
                        return (
                          <div
                            key={key}
                            className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5"
                            data-aos="fade-up"
                            data-aos-once="true"
                          >
                            <div className="card-download p-0">
                              <div>
                                <img className="mw-100" src={`${domain}${screenBox.img.url}`} alt="blogItem" />
                              </div>
                              <div className="text-center">
                                <h5 className="m-0 d-inline-block my-2">{screenBox.title}</h5>
                                {screenBox.files.length > 0 && (
                                  <div className="text-center">
                                    <p key={key} className="d-inline-block">
                                      {screenBox.files.map((file, index) => {
                                        key += `_file_${index}`
                                        return (
                                          <>
                                            <a target="_blank" rel="noopener noreferrer" href={`${domain}${file.url}`} download={`${domain}${file.url}`}>{file.name}</a>
                                            {(index + 1) < screenBox.files.length && (
                                              <span>
                                                {' / '}
                                              </span>
                                            )}
                                          </>
                                        )
                                      })}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                  {zusammenstellung.length > 0 && (
                    <div className="row">
                      {zusammenstellung.map((box, index) => {
                        let key = `${index}_${box.title}` 
                        return (
                          <div 
                            key={key} 
                            className="col-12 col-md-6 mb-5"
                            data-aos="fade-up"
                            data-aos-once="true"
                          >
                            <div className="card-download p-0">
                              <div>
                                <img className="mw-100" src={`${domain}${box.img.url}`} alt="blogItem" />
                              </div>
                              <div className="text-center my-2">
                                <h5 className="m-0 d-inline-block my-2">{box.title}</h5>
                                {box.files.length > 0 && (
                                  <div className="text-center">
                                    <p key={key} className="d-inline-block">
                                      {box.files.map((file, index) => {
                                        key += `_file_${index}`
                                        return (
                                          <>
                                            <a target="_blank" rel="noopener noreferrer" href={`${domain}${file.url}`} download={`${domain}${file.url}`}>{file.name}</a>
                                            {(index + 1) < box.files.length && (
                                              <span>
                                                {' / '}
                                              </span>
                                            )}
                                          </>
                                        )
                                      })}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {presskit && (
        <section
          className="section position-relative section-presskit bg-primary"
          id="section_coopz_features"
        >
          <div className="container-fluid t-cell-middle">
            <div 
              className="row"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="col-12">
                <div className="container p-0">
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 text-center">
                      <h2 className="text-white">{presskit.presskitTitle}</h2>
                    </div>
                  </div>
                  <div className="row mt-3 mt-md-4">
                    <div className="col-12 text-center" >
                      <a href={`${domain}${presskit.file.url}`} target="_blank" rel="noopener noreferrer" download={`${domain}${presskit.file.url}`} className="btn btn-warning text-white small text-uppercase">
                        <b>{language === 'de' ? ('Herunterladen') : ('Download')}</b>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default DownloadsPage


export const pageQuery = graphql`
  query DownloadsPage($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      downloadsPage(id: $id) {
        id
        language
        logoBox {
          img {
            url
          }
          title
          files {
            url
            name
          }
        }
        zusammenstellung {
          files {
            url
            name
          }
          title
          img {
            url
          }
        }
        appScreens {
          files {
            url
            name
          }
          title
          img {
            url
          }
        }
        presskit {
          file {
            url
          }
          presskitTitle
        }
        meta {
          title
          description
        }
      }
      homepages(where: $language) {
        header {
          links {
            label
            slug
          }
          logo {
            url
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
      }
    }
  }
`
